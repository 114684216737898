<template>
    <div class="list">
        <b-row>
            <b-col>
                <!-- Search -->
                <game-items-list-search :search-query.sync="searchQuery">
                    <template #title>
                        <slot name="title"> Game items list </slot>
                    </template>
                </game-items-list-search>
            </b-col>
        </b-row>

        <section
            class="d-flex justify-content-between flex-column flex-lg-row-reverse"
        >
            <!-- Game Items filter -->
            <game-items-list-filter
                class="list__filter align-self-stretch align-self-lg-start"
                :weapons-filter.sync="weaponsFilter"
                :rarities-filter.sync="raritiesFilter"
                :skin-set-filter.sync="skinSetFilter"
                :custom-filter.sync="customFilter"
                :legal-filter.sync="legalFilter"
                :skin-set-options="skinSetOptions"
                :game-item-variant="gameItemVariant"
            />

            <!-- Game Items list -->
            <div class="align-self-start mr-lg-1 w-100 list__grid">
                <b-card class="h-100 primary" no-body>
                    <b-card-body
                        class="d-flex justify-content-center align-items-center"
                        body-text-variant="primary"
                    >
                        <b-card-text>
                            <feather-icon icon="PlusIcon" class="mr-25" />

                            <b-link
                                :to="{ name: `${gameItemVariant}-add` }"
                                class="stretched-link"
                            >
                                {{ $t('ui.add') }}
                            </b-link>
                        </b-card-text>
                    </b-card-body>
                </b-card>

                <game-items-list-card
                    v-for="(gameItem, idx) in gameItems"
                    :key="gameItemVariant + idx"
                    class="list__card"
                    :game-item-variant="gameItemVariant"
                    :game-item="gameItem"
                />
            </div>
        </section>

        <div class="mt-2">
            <b-pagination
                v-model="currentPage"
                pills
                :per-page="perPage"
                :total-rows="gameItemsCount"
                @change="paginationHandleChange"
            />
        </div>
    </div>
</template>

<script>
import {
    BRow,
    BCol,
    BLink,
    BCard,
    BCardBody,
    BCardText,
    BPagination,
} from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive';
import { reactive, watch } from '@vue/composition-api';
import i18n from '@/libs/i18n/index';
import useGameItems from '../useGameItems';
import GameItemsListFilter from './GameItemsListFilter.vue';
import GameItemsListSearch from './GameItemsListSearch.vue';
import GameItemsListCard from './GameItemsListCard.vue';
import { gameItemsVariants } from '../gameItemsUtils';

export default {
    components: {
        BRow,
        BCol,
        BLink,
        BCard,
        BCardBody,
        BCardText,
        BPagination,

        GameItemsListFilter,
        GameItemsListSearch,
        GameItemsListCard,
    },

    directives: {
        Ripple,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },
    },

    computed: {
        gameItems() {
            return this.$store.getters['app-game-items/GET_GAME_ITEMS'];
        },

        gameItemsCount() {
            return this.$store.getters['app-game-items/GET_GAME_ITEMS_TOTAL'];
        },
    },

    setup(props) {
        const { gameItemVariant } = props;

        const {
            searchQuery,
            currentPage,
            perPage,
            skinSetFilter,
            weaponsFilter,
            raritiesFilter,
            customFilter,
            legalFilter,
            skinSetOptions,
            fetchGameItems,
            paginationHandleChange,
        } = useGameItems({ gameItemVariant });

        const i18nReactive = reactive(i18n);

        fetchGameItems({ limit: perPage.value, page: currentPage.value });

        watch(
            () => i18nReactive.locale,
            () => {
                fetchGameItems({
                    limit: perPage.value,
                    page: currentPage.value,
                });
            }
        );

        return {
            // Reactive data
            searchQuery,
            currentPage,
            perPage,
            skinSetFilter,
            weaponsFilter,
            raritiesFilter,
            customFilter,
            legalFilter,
            skinSetOptions,

            // Methods
            paginationHandleChange,
        };
    },
};
</script>

<style lang="scss" scoped>
@import '~@/@core/scss/base/bootstrap-extended/include'; // Bootstrap includes
@import '~@/@core/scss/base/components/include'; // Components includes

::v-deep .list {
    &__grid {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 1rem;
    }

    &__card {
        &:hover {
            transform: translateY(-5px);
            box-shadow: 0 4px 25px 0 rgba($black, 0.25);
        }
    }

    &__filter {
        position: sticky;
        top: 100px;
        align-self: flex-start;
    }
}

@include media-breakpoint-down(lg) {
    ::v-deep .list__grid {
        grid-template-columns: repeat(4, 1fr);
    }
}

@include media-breakpoint-down(md) {
    ::v-deep .list__grid {
        grid-template-columns: repeat(3, 1fr);
    }
}

@include media-breakpoint-down(sm) {
    ::v-deep .list__grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

@include media-breakpoint-down(xs) {
    ::v-deep .list__grid {
        grid-template-columns: 1fr;
    }
}
</style>
