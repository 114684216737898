<template>
    <b-card>
        <!-- Search -->
        <b-row class="d-flex justify-content-between">
            <b-col cols="12" md="6" class="d-flex align-items-center">
                <h4 class="m-0">
                    <slot name="title"> Game items list </slot>
                </h4>
            </b-col>

            <b-col cols="12" xl="5" class="mt-1 mt-xl-0">
                <div class="d-flex align-items-center justify-content-end">
                    <b-form-input
                        :value="searchQuery"
                        class="d-inline-block mr-1"
                        :placeholder="$t('ui.search')"
                        @input="updateValue"
                    />

                    <b-button variant="primary">
                        <span class="text-nowrap">
                            {{ $t('ui.search') }}
                        </span>
                    </b-button>
                </div>
            </b-col>
        </b-row>
    </b-card>
</template>

<script>
import { BCard, BRow, BCol, BFormInput, BButton } from 'bootstrap-vue';

export default {
    components: {
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
    },

    props: {
        searchQuery: {
            type: String,
            required: true,
        },
    },

    methods: {
        updateValue(event) {
            this.$emit('update:searchQuery', event);
        },
    },
};
</script>

<style lang="scss" scoped></style>
