<template>
    <div class="sidebar-detached sidebar-left">
        <div class="sidebar">
            <b-card>
                <b-row>
                    <b-col>
                        <h4 class="m-0">
                            {{ $t('ui.filters') }}
                        </h4>
                    </b-col>
                </b-row>

                <div class="d-flex flex-column mt-2 align-items-start">
                    <!-- Skinset select -->
                    <div class="w-100">
                        <h6>
                            {{ $t('gameItem.set') }}
                        </h6>

                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :value="skinSetFilter"
                            :options="skinSetOptions"
                            class="w-100"
                            multiple
                            :reduce="(val) => val.value"
                            @input="(val) => $emit('update:skinSetFilter', val)"
                        />
                    </div>

                    <!-- Weapons select -->
                    <div v-if="!isGameItemACharacter" class="w-100 mt-2 mb-75">
                        <h6>
                            {{ $t('gameItem.weapon') }}
                        </h6>

                        <weapons-select-single
                            :value="weaponsFilter"
                            :multiple="true"
                            @input="
                                (arrayOfIDs) =>
                                    $emit('update:weaponsFilter', arrayOfIDs)
                            "
                        />
                    </div>

                    <!-- Rarity select -->
                    <div class="w-100 mt-2 mb-75">
                        <h6>
                            {{ $t('gameItem.rarity') }}
                        </h6>

                        <v-select
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :value="raritiesFilter"
                            :options="filteredRarities"
                            class="select w-100"
                            label="name"
                            :reduce="(rarity) => rarity.id"
                            multiple
                            width="100"
                            @input="
                                (rarity) =>
                                    $emit('update:raritiesFilter', rarity)
                            "
                        >
                            <template v-slot:option="option">
                                <span
                                    class="select__circle"
                                    :style="`background-color: ${option.main_color}`"
                                />
                                {{ option.name }}
                            </template>
                        </v-select>
                    </div>

                    <!-- Game Item Custom select -->
                    <div class="w-100 mt-2 mb-75">
                        <h6>
                            {{ $t('gameItem.custom') }}
                        </h6>

                        <b-form-group class="m-0">
                            <b-form-radio-group
                                id="btn-radios-1"
                                v-model="selectedCustomRadio"
                                button-variant="outline-secondary"
                                :options="optionsCustomRadio"
                                stacked
                                name="custom-radios"
                                class="custom-radio-group"
                                @input="
                                    (val) => $emit('update:customFilter', val)
                                "
                            />
                        </b-form-group>
                    </div>

                    <!-- Legal select -->
                    <div class="w-100 mt-2 mb-75">
                        <h6>
                            {{ $t('gameItem.legal') }}
                        </h6>

                        <b-form-group class="m-0">
                            <b-form-radio-group
                                id="btn-radios-2"
                                v-model="selectedLegalRadio"
                                button-variant="outline-secondary"
                                :options="optionsLegalRadio"
                                stacked
                                name="legal-radios"
                                class="legal-radio-group"
                                @input="
                                    (val) => $emit('update:legalFilter', val)
                                "
                            />
                        </b-form-group>
                    </div>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    // BButton,
    BFormGroup,
    BFormRadioGroup,
} from 'bootstrap-vue';
import WeaponsSelectSingle from '@/views/weapons/weapons-select/WeaponsSelectSingle.vue';
import vSelect from 'vue-select';
import Ripple from 'vue-ripple-directive';
import {
    ref,
    reactive,
    onMounted,
    watch,
    // computed
} from '@vue/composition-api';
import i18n from '@/libs/i18n/index';
import useWeapons from '@/views/weapons/useWeapons';
import useGameItems from '../useGameItems';
import { gameItemsVariants } from '../gameItemsUtils';

export default {
    components: {
        BCard,
        BRow,
        BCol,
        // BButton,
        BFormGroup,
        BFormRadioGroup,

        vSelect,

        // WeaponsModalSelect,
        WeaponsSelectSingle,
    },

    directives: {
        Ripple,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
            validator: (gameItemVariant) =>
                gameItemsVariants.includes(gameItemVariant),
        },
        skinSetFilter: {
            type: [Array, null],
            default: null,
        },

        weaponsFilter: {
            type: [Array, null],
            default: null,
        },

        raritiesFilter: {
            type: [Array, null],
            default: null,
        },

        customFilter: {
            type: [Boolean, null],
            default: null,
        },

        legalFilter: {
            type: [Boolean, null],
            default: null,
        },

        skinSetOptions: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            selectedCustomRadio: this.customFilter,
            selectedLegalRadio: this.legalFilter,
            weaponName: this.$t('ui.select'),
        };
    },

    computed: {
        rarities() {
            return this.$store.getters['app-rarities/GET_RARITIES'] || [];
        },

        filteredRarities() {
            return this.rarities.filter((rarity) => {
                if (this.customFilter === null) return true;

                return rarity.is_custom === this.customFilter;
            });
        },
    },

    watch: {
        filteredRarities() {
            this.$emit('update:raritiesFilter', []);
        },
    },

    setup(props) {
        const { gameItemVariant } = props;
        const { isGameItemACharacter } = useGameItems({ gameItemVariant });
        const { getWeaponByID } = useWeapons();

        const i18nReactive = reactive(i18n);

        const optionsCustomRadio = ref([]);
        const optionsLegalRadio = ref([]);

        const localizeOptions = () => {
            optionsCustomRadio.value = [
                { text: i18nReactive.t('ui.all'), value: null },
                { text: i18nReactive.t('gameItem.customOnly'), value: true },
                { text: i18nReactive.t('gameItem.standart'), value: false },
            ];

            optionsLegalRadio.value = [
                { text: i18nReactive.t('gameItem.any'), value: null },
                { text: i18nReactive.t('ui.yes'), value: true },
                { text: i18nReactive.t('ui.no'), value: false },
            ];
        };

        onMounted(localizeOptions);

        watch(
            () => i18nReactive.locale,
            () => localizeOptions()
        );

        return {
            // Reactive data
            isGameItemACharacter,
            optionsCustomRadio,
            optionsLegalRadio,

            // Methods
            getWeaponByID,
        };
    },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '~@/utils/scss/vue-select-rarity.scss';

.custom-radio-group,
.legal-radio-group {
    .custom-control {
        margin-bottom: 0.75rem;
    }
}
</style>
