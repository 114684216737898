<template>
    <b-card no-body class="card m-0 h-100" tag="article">
        <div class="item-img text-center">
            <b-link
                :to="{
                    name: `${gameItemVariant}-edit`,
                    params: { id: gameItem.id },
                }"
                class="card__square"
            >
                <b-img-lazy
                    v-if="gameItem.image_url"
                    :alt="`${gameItem.name}-${gameItem.id}`"
                    fluid
                    class="card__image card-img-top"
                    :src="gameItem.image_url"
                />

                <span v-else class="text-muted centered">
                    <feather-icon class="mr-25" icon="ImageIcon" />
                    {{ $t('ui.noImage') }}
                </span>
            </b-link>
        </div>

        <b-card-body class="p-1">
            <b-link
                class="text-body"
                :to="{
                    name: `${gameItemVariant}-edit`,
                    params: { id: gameItem.id },
                }"
            >
                <b-card-text>
                    {{ cardFooterText }}
                </b-card-text>
            </b-link>
        </b-card-body>
    </b-card>
</template>

<script>
import { BCard, BLink, BImgLazy, BCardBody, BCardText } from 'bootstrap-vue';
import useWeapons from '@/views/weapons/useWeapons';

export default {
    components: {
        BCard,
        BLink,
        BImgLazy,
        BCardBody,
        BCardText,
    },

    props: {
        gameItemVariant: {
            type: String,
            required: true,
        },

        gameItem: {
            type: Object,
            required: true,
        },
    },

    computed: {
        weaponName() {
            const { weapon_id } = this.gameItem;
            const weapon = this.getWeaponByID(weapon_id);

            return weapon.name || '';
        },

        cardFooterText() {
            if (!this.weaponName) return this.gameItem.name;

            return `${this.weaponName} | ${this.gameItem.name}`;
        },
    },

    setup() {
        const { getWeaponByID } = useWeapons();

        return {
            // Methods
            getWeaponByID,
        };
    },
};
</script>

<style lang="scss" scoped>
.card {
    &__square {
        position: relative;

        display: flex;

        &::before {
            content: '';
            position: relative;
            width: 100%;
            padding-bottom: 75%;
        }
    }

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        text-align: center;
    }
}
</style>
